import React from "react"
import {graphql, PageProps} from "gatsby"

import Layout from "../components/layout"
import {Form, Description} from "../components/contact"
import {ContactQuery} from "./__generated__/ContactQuery"

export default ({data, location}: PageProps<ContactQuery>) => {

    const api_url = data.site.siteMetadata.contact.api_url;
    const hasContactForm = (api_url);
    return (
            <div style={{ color: "white" }}>
                {hasContactForm &&
                    <div>
                        <Form api={api_url}/>
                    </div>
                }
            </div>)
}

export const query = graphql`
    query ContactQueryProducts {
        site {
            siteMetadata {
                contact {
                    api_url
                    description
                    mail
                    phone
                    address
                }
            }
        }
    }
`
